import React from "react";

import BaseButton from "components/common/Buttons/BaseButton";
import { navigate } from "gatsby";
import { FaApple, FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import SEO from "../../components/seo";
import { IAuth, useLogin } from "../../hooks/auth/useLogin";

import "./login.css";


const DEFAULT_SOCIAL_MEDIA_SIZE = 24;

const LoginPage: React.FunctionComponent = (props) => {
  const { loginByGoogle, loginByApple, loginByFacebook } = useLogin({
    onCompleted: () => navigate("/"),
  });

  return (
    <>
      <SEO title="Login" />

      <div className="w-full h-full mx-auto flex flex-col items-center justify-center gap-y-4">
        <h1 className="font-black text-2xl text-secondary-500">
          Sign up with{" "}
        </h1>
        <br />

        <div className="flex justify-around gap-x-1 rounded-lg shadow-lg w-auto">
          <div className="btn-auth">
            <BaseButton
              name={IAuth.GOOGLE}
              onClick={loginByGoogle}
              className={IAuth.GOOGLE}
            >
              <FcGoogle size={DEFAULT_SOCIAL_MEDIA_SIZE} />
            </BaseButton>
            <h4 className="mb-5">Google</h4>
          </div>

          {/* <div className="btn-auth">
            <BaseButton
              name="facebook"
              onClick={loginByFacebook}
              className={"facebook"}
            >
              <FaFacebookF size={DEFAULT_SOCIAL_MEDIA_SIZE} />
            </BaseButton>
            <h4 className="mb-5">Facebook</h4>
          </div> */}

          <div className="btn-auth">
            <BaseButton
              name={IAuth.APPLE}
              onClick={loginByApple}
              className={IAuth.APPLE}
            >
              <FaApple size={DEFAULT_SOCIAL_MEDIA_SIZE} />
            </BaseButton>
            <h4 className="mb-5">Apple</h4>
          </div>
        </div>
        <h3 className="text-center font-bold">
          By signing up, you agree to Creato’s{" "}
          <a
            className="underline"
            href="https://www.creatogether.app/privacypolicy"
          >
            terms
          </a>{" "}
          and{" "}
          <a className="underline" href="https://www.creatogether.app/terms">
            privacy policy
          </a>
          .
        </h3>
      </div>
    </>
  );
};

export default LoginPage;
